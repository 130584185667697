<template>
  <v-container fluid>
    <v-row dense>
      <snackbar refs="snackbar"></snackbar>
      <!-- <v-col cols="12" sm="6" md="4" lg="3">
        <v-text-field
          v-if="record"
          outlined
          dense
          label="Code"
          v-model="record.Code"
        ></v-text-field>
      </v-col> -->
      <v-col cols="12" sm="6" md="4" lg="3">
        <v-text-field
          v-if="record"
          outlined
          dense
          label="Name"
          v-model="record.Name"
        ></v-text-field>
      </v-col>
      <v-col cols="12" sm="6" md="4" lg="3">
        <v-checkbox
          v-if="record"
          outlined
          dense
          v-model="record.Active"
          label="Active"
        ></v-checkbox>
      </v-col>

      <v-col cols="12" sm="6" md="4" lg="3">
        <v-autocomplete
          outlined
          dense
          v-model="record.Type"
          :items="Type"
          item-text="text"
          item-value="value"
          label="Type"
        ></v-autocomplete>
      </v-col>
      <v-col cols="12" sm="6" md="4" lg="3">
        <v-autocomplete
          outlined
          dense
          multiple
          v-model="record.alt4"
          :items="Query"
          item-text="QName"
          item-value="id"
          label="Query"
        ></v-autocomplete>
      </v-col>
    </v-row>
    <v-row dense>
      <v-col cols="12" sm="6" md="4" lg="3">
        <v-autocomplete
          outlined
          dense
          v-model="record.Priority"
          :items="Priority"
          item-text="text"
          item-value="value"
          label="Priority"
        ></v-autocomplete>
      </v-col>
      <v-col cols="12" sm="6" md="4" lg="3">
        <v-text-field
          outlined
          dense
          v-model="record.FrqncyIntr"
          label="On Every"
          type="number"
        ></v-text-field>
      </v-col>

      <v-col cols="12" sm="6" md="4" lg="3">
        <v-autocomplete
          outlined
          dense
          v-model="record.FrqncyType"
          :items="FrqncyType"
          item-text="text"
          item-value="value"
          label="Frequency Type"
        ></v-autocomplete>
      </v-col>
      <v-col
        v-if="record.FrqncyType == 'W' || record.FrqncyType == 'M'"
        cols="12"
        sm="6"
        md="4"
        lg="3"
      >
        <v-autocomplete
          outlined
          dense
          v-model="record.ExecDaY"
          item-text="text"
          item-value="value"
          label="Execution Day"
          :items="autocompleteItems"
        ></v-autocomplete>
      </v-col>
      <v-col
        v-if="
          record.FrqncyType == 'D' ||
          record.FrqncyType == 'W' ||
          record.FrqncyType == 'M'
        "
        cols="12"
        sm="6"
        md="4"
        lg="3"
      >
        <v-menu
          ref="menu"
          v-model="menu2"
          :close-on-content-click="false"
          :nudge-right="40"
          :return-value.sync="time2"
          transition="scale-transition"
          offset-y
          max-width="290px"
          min-width="290px"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              v-model="record.ExecTime"
              label="At"
              readonly
              outlined
              v-bind="attrs"
              v-on="on"
            ></v-text-field>
          </template>
          <v-time-picker
            v-if="menu2"
            v-model="record.ExecTime"
            full-width
            @click:minute="$refs.menu.save(time2)"
          ></v-time-picker>
        </v-menu>
      </v-col>
    </v-row>
    <template>
      <v-tabs background-color="primary" dark dense>
        <v-tab active-class="info"> Users </v-tab>
        <v-tab active-class="info"> Groups </v-tab>
        <v-tab active-class="info"> Mail Template </v-tab>
        <v-tab active-class="info"> SMS Template</v-tab>
        <v-tab-item>
          <v-card>
            <v-col class="mt-3" cols="12">
              <v-text-field
                class="p-3"
                dense
                v-model="userSearch"
                label="Search"
              ></v-text-field>
              <v-data-table
                mobile-breakpoint="md"
                v-model="record.alt1"
                dense
                :loading="loading"
                loading-text="Loading... Please wait"
                :headers="headers"
                :items="selectedItems"
                :search="userSearch"
                show-select
                item-key="UserSign"
              >
                <template v-slot:item.SendIntrnl="{ item }">
                  <v-checkbox
                    v-model="item.SendIntrnl"
                    :value="item.SendIntrnl"
                  ></v-checkbox>
                </template>
                <template v-slot:item.SendEMail="{ item }">
                  <v-checkbox
                    v-model="item.SendEMail"
                    :value="item.SendEMail"
                  ></v-checkbox>
                </template>
                <template v-slot:item.SendSMS="{ item }">
                  <v-checkbox
                    v-model="item.SendSMS"
                    :value="item.SendSMS"
                  ></v-checkbox>
                </template>
              </v-data-table>
            </v-col>
          </v-card>
        </v-tab-item>
        <v-tab-item>
          <v-data-table
            mobile-breakpoint="md"
            v-model="record.alt2"
            :headers="headers"
            :items="selectedItems2"
            show-select
            dense
            item-key="GroupId"
          >
            <template v-slot:item.SendIntrnl="{ item }">
              <v-checkbox
                v-model="item.SendIntrnl"
                :value="item.SendIntrnl"
              ></v-checkbox>
            </template>
            <template v-slot:item.SendEMail="{ item }">
              <v-checkbox
                v-model="item.SendEMail"
                :value="item.SendEMail"
              ></v-checkbox>
            </template>
            <template v-slot:item.SendSMS="{ item }">
              <v-checkbox
                v-model="item.SendSMS"
                :value="item.SendSMS"
              ></v-checkbox>
            </template>
          </v-data-table>
        </v-tab-item>
        <v-tab-item>
          <v-row class="mt-4">
            <v-col cols="12" sm="6" md="4" lg="3">
              <v-text-field
                v-if="record"
                outlined
                dense
                label="Subject"
                v-model="mail_template.tempSubject"
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="6" md="4" lg="3">
              <v-text-field
                v-if="record"
                outlined
                dense
                label="Title"
                v-model="mail_template.tempTitle"
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="6" md="4" lg="3">
              <v-text-field
                v-if="record"
                outlined
                dense
                label="Code"
                v-model="mail_template.tempCode"
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="6" md="4" lg="3">
              <v-autocomplete
                outlined
                dense
                multiple
                v-model="record.alt6"
                :items="Query"
                item-text="QName"
                item-value="id"
                label="Select Query To send as attachment"
              ></v-autocomplete>
            </v-col>

            <v-col cols="4">
              <v-autocomplete
                outlined
                dense
                multiple
                v-model="record.variables"
                item-text="variable_key"
                item-value="variable_value"
                label="Variables"
                :items="Variables"
              ></v-autocomplete>
            </v-col>
            <v-col cols="8">
              <v-textarea
                v-if="record"
                v-model="mail_template.tempBody"
                outlined
                dense
                label="Body"
              >
              </v-textarea>
            </v-col>
          </v-row>
        </v-tab-item>
        <v-tab-item>
          <v-row class="mt-4">
            <v-col cols="12" sm="6" md="6" lg="10">
              <v-select
                v-if="record"
                outlined
                dense
                label="Select Subject"
                :items="subjectOptions"
                v-model="sms_template.selectedSubject"
                @change="updateSMSBody"
              ></v-select>
            </v-col>
            <v-col cols="12" sm="6" md="4" lg="4">
              <v-autocomplete
                outlined
                dense
                multiple
                v-model="record.alt7"
                :items="Query"
                item-text="QName"
                item-value="id"
                label="Select Query To add to the message"
              ></v-autocomplete>
            </v-col>

            <v-col cols="8">
              <v-textarea
                v-if="record"
                :value="compiledSMS"
                outlined
                dense
                label="Body"
              >
              </v-textarea>
            </v-col>
          </v-row>
        </v-tab-item>
      </v-tabs>
    </template>
    <v-row dense>
      <v-col cols="12">
        <v-btn color="accent" @click="sendData" :loading="loader">
          <v-icon left>mdi-content-trash</v-icon>Save
        </v-btn>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  props: {
    initial: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      userSearch: "",
      record: {},
      mail_template: {},
      sms_template: {
        selectedSubject: "",
        tempBody: "",
        completeMessage: ""
      },
      subjectOptions: [
        "Exclusive offer alert! Get ready for big savings on [Product Name] with our latest price update.",
        "Don't miss out! We're thrilled to announce a special offer on [Product Name] with our new price adjustment.",
        "Amazing news! Take advantage of our limited-time offer on [Product Name] with our recent price change.",
        "Hurry, limited time only! Discover incredible savings on [Product Name] thanks to our updated pricing.",
        "Exciting offer alert! You're in luck - we've just dropped the price on [Product Name] for a limited time."
      ],
      users: [],
      selectedItems: [],
      selectedItems2: [],
      headers: [
        { text: "#", value: "UserSign" },
        { text: "Name", value: "name" },
        { text: "SendEMail", value: "SendEMail" },
        { text: "SendSMS", value: "SendSMS" },
        { text: "SendIntrnl", value: "SendIntrnl" },
      ],
      loader: false,
      loading: false,
      fields: [],
      field_types: [],
      search: "",
      selected: [],
      userGroups: [],
      Query: [],
      menu2: false,
      menu1: false,
      time1: "",
      time2: "",
      Variables: [],
      Type: [
        { value: "S", text: "System Alert" },
        { value: "U", text: "User Alert" },
      ],
      Priority: [
        { value: 0, text: "Low" },
        { value: 1, text: "Normal" },
        { value: 2, text: "High" },
      ],
      FrqncyType: [
        { value: "S", text: "Minutes" },
        { value: "H", text: "Hours" },
        { value: "D", text: "Days" },
        { value: "W", text: "Weeks" },
        { value: "M", text: "Months" },
      ],
      FrqncyTypeOptions: {
        W: [
          { value: "0", text: "Sunday" },
          { value: "1", text: "Monday" },
          { value: "2", text: "Tuesday" },
          { value: "3", text: "Wednesday" },
          { value: "4", text: "Thursday" },
          { value: "5", text: "Friday" },
          { value: "6", text: "Saturday" },
        ],
        M: [],
      },
      status: true,
    };
  },

  watch: {
    initial: {
      handler: "init",
      immediate: true,
    },
  },
  computed: {
    autocompleteItems() {
      return this.FrqncyTypeOptions[this.record.FrqncyType] || [];
    },
    compiledSMS() {
      const productName = "Adult Diapers";
      const openingLine = this.sms_template.selectedSubject.replace("[Product Name]", productName);
      const body = this.sms_template.tempBody;
      return openingLine + "\n" + body ;
    }
  },

  methods: {
    init(val) {
      // console.log(val, "initial");
      this.loading = false;
      this.record = { ...val };
    },
    updateSMSBody() {      
      this.$set(this.sms_template, "selectedOpeningLine", this.sms_template.selectedSubject);
      this.sms_template.completeMessage = this.compiledSMS;
    },
    sendData() {
      let data = this.record;
      data.alt5 = {
        tempSubject: this.mail_template.tempSubject,
        tempTitle: this.mail_template.tempTitle,
        tempCode: this.mail_template.tempCode,
        tempBody: this.mail_template.tempBody,
      };
      // console.log(data);
      const self = this;
      self.loader = false;

      this.$store.commit("loader", true);
      this.$emit("data", data, () => {
        self.$store.commit("loader", false);
      });
    },
    getUsers() {
      const self = this;
      this.loading = true;
      this.$store
        .dispatch("get", "/users")
        .then((res) => {
          self.Items = res.ResponseData;
          this.loading = false;
          self.selectedItems = self.Items.map((item1) => {
            const matchingItem = self.record.alt1?.find(
              (item2) => item2.UserSign === item1.id
            );

            const SendEMail = self.record.alt1?.some(
              (item2) => item1.id == item2.UserSign && item2.SendEMail
            );
            const SendSMS = self.record.alt1?.some(
              (item2) => item1.id == item2.UserSign && item2.SendSMS
            );
            const SendIntrnl = self.record.alt1?.some(
              (item2) => item1.id == item2.UserSign && item2.SendIntrnl
            );
            return {
              ...item1,
              UserSign: matchingItem ? matchingItem.UserSign : item1.id,
              SendEMail: SendEMail,
              SendSMS: SendSMS,
              SendIntrnl: SendIntrnl,
            };
          });
          console.log(self.selectedItems);
        })
        .catch((err) => {
          this.$refs.snackbar.show(err, "red");
        });
    },
    getGroups() {
      const url = "/usergroup";
      const self = this;
      self.loader = true;
      this.$store
        .dispatch("get", url)
        .then((res) => {
          self.loader = false;
          self.userGroups = res.ResponseData;
          self.selectedItems2 = self.userGroups.map((item1) => {
            const matchingItem = self.record.alt2?.find(
              (item2) => item1.id == item2.GroupId
            );
            const SendEMail = self.record.alt2?.some(
              (item2) => item1.id == item2.GroupId && item2.SendEMail
            );
            const SendSMS = self.record.alt2?.some(
              (item2) => item1.id == item2.GroupId && item2.SendSMS
            );
            const SendIntrnl = self.record.alt2?.some(
              (item2) => item1.id == item2.GroupId && item2.SendIntrnl
            );
            return {
              ...item1,
              GroupId: matchingItem ? matchingItem.GroupId : item1.id,
              SendEMail: SendEMail,
              SendSMS: SendSMS,
              SendIntrnl: SendIntrnl,
            };
          });
        })
        .catch((err) => {
          console.log(err, "err");
          // this.$refs.snackbar.show(err, "red");
        });
    },
    getQuery() {
      const self = this;
      this.loading = true;
      this.$store
        .dispatch("get", "/query_manager")
        .then((res) => {
          self.Query = res.ResponseData;
          this.loading = false;
        })
        .catch((err) => {
          this.$refs.snackbar.show(err, "red");
        });
    },
    getTemplate(id) {
      // const self = this;
      this.loading = true;
      this.$store
        .dispatch("get", `alerts/mail_template/show/${id}`)
        .then((res) => {
          // self.mail_template = res.ResponseData;
          this.mail_template = { ...res.ResponseData };
          this.loading = false;
        })
        .catch((err) => {
          this.$refs.snackbar.show(err, "red");
        });
    },
    countRange() {
      let numbers = [];
      for (let i = 1; i <= 31; i++) {
        numbers.push(i);
      }
      this.FrqncyTypeOptions.M = numbers;
    },
    getVariables() {
      const self = this;
      this.loading = true;
      this.$store
        .dispatch("get", `/alert/variables`)
        .then((res) => {
          if (res.ResultCode == 1200) {
            self.Variables = res.ResponseData;
            self.loading = false;
          }
          if (res.ResultCode == 1043) {
            this.$refs.snackbar.show(res.ResultDesc, "red");
            self.loading = false;
          }
        })
        .catch((err) => {
          this.$refs.snackbar.show(err, "red");
        });
    },
  },
  mounted() {
    this.getUsers();
    this.getGroups();
    this.countRange();
    this.getQuery();
    this.getVariables();
    this.getTemplate(this.$route.params.id);
  },
};
</script>
